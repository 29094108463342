import { useLayoutEffect, useRef, useState } from "react";
import { BiPhoneCall, BiMailSend, BiCopy } from "react-icons/bi";
import { motion } from "framer-motion";
import { cn } from "../../utils/cn";

const Footer = () => {
    const phoneRef = useRef<HTMLAnchorElement>(null);
    const phoneTextRef = useRef<HTMLSpanElement>(null);
    const emailRef = useRef<HTMLAnchorElement>(null);
    const emailTextRef = useRef<HTMLSpanElement>(null);
    const [phoneCopied, setPhoneCopied] = useState(false);
    const [emailCopied, setEmailCopied] = useState(false);

    const copyToClipboard = (text: string, type: "email" | "phone") => {
        navigator.clipboard.writeText(text).then(() => {
            if (type === "email") setEmailCopied(true);
            else setPhoneCopied(true);
            setTimeout(() => {
                if (type === "email") setEmailCopied(false);
                else setPhoneCopied(false);
            }, 3000);
        });
    };

    useLayoutEffect(() => {
        const phoneEl = phoneRef.current;
        const phoneTextEl = phoneTextRef.current;
        if (!phoneEl || !phoneTextEl) return;
        const tel = ["0048", "535", "201", "968"];
        phoneEl.href = `tel:${tel[0] + tel[1] + tel[2] + tel[3]}`;
        phoneTextEl.textContent = `${tel[0]}-${tel[1]}-${tel[2]}-${tel[3]}`;
    }, []);

    useLayoutEffect(() => {
        const emailEl = emailRef.current;
        const emailTextEl = emailTextRef.current;
        if (!emailEl || !emailTextEl) return;
        const email = ["zielinska", ".", "karolinak", "@gmail.com"];
        emailEl.href = `mailto:${email[0] + email[1] + email[2] + email[3]}`;
        emailTextEl.textContent = `${email[0]}${email[1]}${email[2]}${email[3]}`;
    }, []);

    return (
        <footer
            className="flex flex-col justify-end overflow-hidden pb-20 md:pb-0"
            id="kontakt"
        >
            <div className="bg-text/20 w-full h-px" />
            <div className="custom-screen flex items-center justify-between gap-4 flex-wrap pt-8 pb-24">
                <div className="flex justify-between w-full flex-wrap gap-y-6">
                    <div>
                        <div className="flex flex-col gap-y-2 mb-4">
                            <h1 className="text-2xl">
                                Bereit, um Ihr Leben in Polen zu erleichtern?
                            </h1>
                            <p className="max-w-[56ch]">
                                Ob es sich um eine Frage, eine konkrete Bitte
                                oder einfach um Rat handelt, wir sind hier, um
                                Ihnen bei jedem Schritt zu helfen.
                            </p>
                        </div>
                        <div className="pt-1 flex flex-col gap-2">
                            <div className="flex gap-2">
                                <a
                                    ref={phoneRef}
                                    href="#"
                                    className="flex gap-2 items-center border border-text/20 transition-all rounded-full px-4 py-2 hover:border-text"
                                >
                                    <BiPhoneCall />{" "}
                                    <span ref={phoneTextRef}>
                                        +48 733 489 649
                                    </span>
                                </a>
                                <button
                                    aria-label="telefon kopieren"
                                    onClick={() =>
                                        copyToClipboard(
                                            phoneTextRef.current?.textContent ||
                                                "",
                                            "phone"
                                        )
                                    }
                                    className={cn(
                                        "flex items-center border border-text/20 transition-all rounded-full px-4 py-2 hover:border-text",
                                        phoneCopied &&
                                            "border-green-500 hover:border-green-500"
                                    )}
                                >
                                    <BiCopy />
                                </button>
                            </div>
                            <div className="flex gap-2">
                                <a
                                    ref={emailRef}
                                    href="#"
                                    className="flex gap-2 items-center border border-text/20 transition-all rounded-full px-4 py-2 hover:border-text"
                                >
                                    <BiMailSend />{" "}
                                    <span ref={emailTextRef}>
                                        +48 733 489 649
                                    </span>
                                </a>
                                <button
                                    aria-label="E-Mail kopieren"
                                    onClick={() =>
                                        copyToClipboard(
                                            emailTextRef.current?.textContent ||
                                                "",
                                            "email"
                                        )
                                    }
                                    className={cn(
                                        "flex items-center border border-text/20 transition-all rounded-full px-4 py-2 hover:border-text",
                                        emailCopied &&
                                            "border-green-500 hover:border-green-500"
                                    )}
                                >
                                    <BiCopy />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <h2 className="text-xl max-w-[32ch]">
                            Machst du dir Sorgen um den Preis?
                        </h2>
                        <p className="max-w-[60ch]">
                            Die Preise der Dienstleistungen werden individuell
                            festgelegt, unter Berücksichtigung der Bedürfnisse
                            jedes Kunden. Die Preise können je nach Standort,
                            Entfernung und Anzahl der gewünschten
                            Dienstleistungen variieren.
                        </p>
                    </div>
                </div>
            </div>

            <motion.img
                src="/name.svg"
                className="w-full h-full"
                initial={{ x: -500, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
                alt="Karolina Zielińska logo"
            />

            <div className="px-[20px] py-2 md:pt-0 flex flex-wrap justify-center md:justify-between items-center">
                <p className="opacity-20" aria-hidden>
                    2024 Karolina Zielińska ©️ Alle Rechte vorbehalten
                </p>
                <p className="opacity-20" aria-hidden>
                    Created by Cywinskiweb
                </p>
            </div>
        </footer>
    );
};

export default Footer;
