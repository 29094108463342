import { cn } from "../../utils/cn";

const Brand = ({ muted = false }: { muted?: boolean }) => {
    return (
        <a
            className={cn(
                "font-deco transition-all hover:opacity-80",
                muted && "opacity-50"
            )}
            href="#heim"
        >
            <p className="text-lg flex">
                K<span className="hidden sm:block">arolina&nbsp;</span>Z
                <span className="hidden sm:block">ielińska</span>
            </p>
        </a>
    );
};

export default Brand;
