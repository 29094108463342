import {
    BiBriefcase,
    BiInfoCircle,
    BiPhoneCall,
    BiUniversalAccess,
} from "react-icons/bi";

const navitems = [
    {
        text: "Für wen?",
        url: "#fur-wen",
        icon: <BiUniversalAccess className="md:hidden" />,
    },
    {
        text: "Über mich",
        url: "#uber-mich",
        icon: <BiInfoCircle className="md:hidden" />,
    },
    {
        text: "Dienstleistungen",
        url: "#dienstleistungen",
        icon: <BiBriefcase className="md:hidden" />,
    },
];

const Navbar = ({ contact = true }: { contact?: boolean }) => {
    return (
        <nav className="flex border-[1px] gap-2 text-base text-text items-center fixed bottom-2 left-1/2 -translate-x-1/2 md:translate-x-0 md:left-0 md:relative bg-bg rounded-full p-1">
            {navitems.map((e, i) => {
                return (
                    <a
                        className="text-lg md:text-base whitespace-nowrap px-4 py-2 rounded-full border hover:border-text border-transparent transition-all"
                        href={e.url}
                        key={i}
                    >
                        <span className="absolute opacity-0 pointer-events-none md:relative md:opacity-100 md:pointer-events-auto">
                            {e.text}
                        </span>{" "}
                        {e.icon}
                    </a>
                );
            })}
            {contact && (
                <a
                    href="#kontakt"
                    className="text-lg md:text-base px-4 py-2 border border-text/20 rounded-full hover:border-text transition-all"
                >
                    <span className="absolute opacity-0 pointer-events-none md:relative md:opacity-100 md:pointer-events-auto">
                        Kontakt
                    </span>{" "}
                    <BiPhoneCall className="md:hidden" />
                </a>
            )}
        </nav>
    );
};

export default Navbar;
