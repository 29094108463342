import { useRef } from "react";
import Navbar from "../navbar";
import Brand from "../brand";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Header = () => {
    const headerRef = useRef<HTMLElement>(null);
    useGSAP(() => {
        gsap.to(headerRef.current, {
            scrollTrigger: {
                trigger: headerRef.current,
                start: "bottom top",
                end: "500% top",
                scrub: true,
                // markers: true,
            },
            color: "rgba(244, 244, 244,1)",
            backgroundColor: "rgba(8, 8, 8,1)",
            duration: 10,
            ease: "power2.out",
        });
    });

    return (
        <header
            ref={headerRef}
            className="z-[999] transition-all text-bg fixed top-0 left-0 w-full custom-screen py-4 flex items-center justify-center md:justify-between"
        >
            <Brand />
            <Navbar />
        </header>
    );
};

export default Header;
