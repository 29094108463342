import Footer from "./components/footer";
import Header from "./components/header";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { motion } from "framer-motion";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const offerlist = [
    {
        title: "Sprachliche Unterstützung für Persönliche und Berufliche Angelegenheiten",
        description:
            "Jeglichen anderen persönlichen oder beruflichen Angelegenheiten, die sprachliche Unterstützung erfordern",
        icon: "",
    },
    {
        title: "Terminvereinbarungen mit Lokalen Behörden und Dienstleistern",
        description:
            "Vereinbarung von Terminen mit lokalen Behörden und Dienstleistern",
        icon: "",
    },
    {
        title: "Bau- und Renovierungsunternehmen: Suche, Unterstützung und Übersetzung",
        description:
            "Hilfe bei der Suche nach einem Bau- und Renovierungsunternehmen sowie Unterstützung und Übersetzung bei der Festlegung des Renovierungsablaufs.",
        icon: "",
    },
    {
        title: "Organisation und Kommunikationshilfe für Angelegenheiten in Polen",
        description:
            "Unterstützung bei der Kommunikation und Organisation verschiedener Angelegenheiten in Polen",
        icon: "",
    },
    {
        title: "Durchdachter Umzug nach Polen",
        description:
            "Ihren Umzug nach Polen reibungslos zu gestalten - von der Suche nach Wohnraum bis zur Integration in das neue Umfeld.",
        icon: "",
    },
    {
        title: "Effektive Kommunikation mit polnischen Immobilienagenturen",
        description:
            "Die Kommunikation mit Immobilienagenturen in Polen und Ihre Anforderungen klar zu kommunizieren.",
        icon: "",
    },
    {
        title: "Professionelle Unterstützung bei Dokumenten und Formalitäten in Polen",
        description:
            "Verlassen Sie sich auf unsere Expertise bei der Handhabung von offiziellen Dokumenten und Formalitäten in Polen, damit Sie sich auf Ihr Geschäft konzentrieren können.",
        icon: "",
    },
];

const helplist = [
    {
        content: "Umziehend nach Polen",
    },
    {
        content: "Immobilienkäufer",
    },
    {
        content: "Die Behördenangelegenheiten erledigen",
    },
    {
        content: "Die einen Dolmetscherin benötigen",
    },
];

function App() {
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    useGSAP(() => {
        const container = containerRef.current;
        const content = contentRef.current;

        if (container && content) {
            gsap.to(content, {
                yPercent: -15 * (offerlist.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: container,
                    pin: true,
                    scrub: true,
                    start: "-5% top",
                    end: "+=" + container.offsetHeight * offerlist.length,
                },
            });
        }
    });

    return (
        <>
            <Header />
            <section
                className="relative h-[75vh] flex flex-col items-center justify-center"
                id="heim"
            >
                <div className="absolute top-0 left-0 w-full h-full z-[-1]">
                    <img
                        src="/bg.webp"
                        alt="Dekoratives Bild. Weißer Schreibtisch mit Pflanze darauf in weißem Topf neben weißer Schreibtischlampe. Holzuhr an der weißen Wand"
                        className="w-full object-cover h-full"
                    />
                </div>
                <div className="text-center flex flex-col items-center">
                    <motion.h1
                        className="text-2xl md:text-4xl lg:text-5xl drop-shadow-md leading-none"
                        initial={{
                            opacity: 0,
                            y: 100,
                        }}
                        animate={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{
                            duration: 1,
                        }}
                    >
                        Ihre Brücke <br />
                        zwischen Sprachen
                    </motion.h1>
                    <motion.p
                        className="mt-4 max-w-[42ch]"
                        initial={{
                            opacity: 0,
                            y: 100,
                        }}
                        animate={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{
                            duration: 1,
                        }}
                    >
                        Professionelle Unterstützung auf Polnisch für
                        deutschsprachige Personen.
                    </motion.p>
                </div>
                <div className="w-full absolute bottom-0 left-0 flex flex-col justify-end">
                    <div className="mx-auto inline-flex gap-2 translate-y-1/2">
                        <a
                            className="px-4 py-2 bg-primary text-bg rounded-full"
                            href="#kontakt"
                        >
                            Kontakt
                        </a>
                        <a
                            className="px-4 py-2 bg-text text-bg rounded-full"
                            href="#dienstleistungen"
                        >
                            Finde mehr
                        </a>
                    </div>
                    <img
                        src="/rect-deco.svg"
                        className="mx-auto"
                        aria-hidden
                        alt="deco"
                    />
                </div>
            </section>
            <section
                className="custom-screen"
                ref={containerRef}
                id="dienstleistungen"
            >
                <div className="text-center flex flex-col items-center py-16">
                    <h1 className="text-2xl mb-4">Dienstleistungen</h1>
                    <p className="max-w-[56ch]">
                        Der Bereich meiner Dienstleistungen umfasst
                        hauptsächlich die Woiwodschaft Westpommern, insbesondere
                        Świnoujście und seine Umgebung. Ich bin hier, um Ihnen
                        das Leben zu erleichtern.
                    </p>
                </div>
                <div className="w-full h-px bg-text/20" />
                <div className="px-[3vw] py-16 max-h-[50vh] overflow-hidden">
                    <div className="bg-gradient-to-t from-bg w-full h-[15vh] md:h-[30vh] absolute bottom-[3.6rem] md:bottom-[4.5rem] left-0 z-10" />
                    <div className="flex flex-col gap-16" ref={contentRef}>
                        {offerlist.map((offer, idx) => (
                            <div
                                className="flex flex-wrap w-full justify-between"
                                key={idx}
                            >
                                <h2 className="text-lg md:text-2xl font-bold max-w-[20ch]">
                                    {offer.title}
                                </h2>
                                <p className="mt-2 max-w-[32ch]">
                                    {offer.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-center py-4">
                    <p className="font-deco text-lg md:text-xl">
                        Lösung alltäglicher Probleme{" "}
                    </p>
                </div>
            </section>
            <section className="relative" id="fur-wen">
                <img
                    src="/bg2.webp"
                    alt="Dekoratives Bild"
                    className="absolute top-0 left-0 w-full h-full z-[-1] py-px"
                />
                <div className="custom-screen">
                    <div className="w-full h-px bg-text/20" />
                    <div className="py-16 flex flex-col md:flex-row justify-between items-center px-[3vw]">
                        <div className="text-center md:text-left flex flex-col items-center md:items-start">
                            <h1 className="text-2xl mb-2">Für wen?</h1>
                            <p className="max-w-[42ch] mb-4">
                                Unsere Dienstleistungen sind für jeden gedacht,
                                der sich von den Herausforderungen einer neuen
                                Sprache und Kultur überwältigt fühlt.
                            </p>
                            <div className="flex gap-2">
                                <a
                                    className="px-4 py-2 bg-primary text-bg rounded-full"
                                    href="#kontakt"
                                >
                                    Kontakt
                                </a>
                                <a
                                    className="px-4 py-2 bg-text text-bg rounded-full"
                                    href="#uber-mich"
                                >
                                    Finde mehr
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col gap-x-8 md:flex-row flex-wrap justify-end">
                            {helplist.map((help, idx) => {
                                return (
                                    <div
                                        className="flex flex-col gap-2 mt-4 md:min-w-[45%]"
                                        key={idx}
                                    >
                                        <span className="font-deco text-xl">
                                            {idx <= 9 ? 0 : null}
                                            {idx + 1}
                                        </span>
                                        <div className="w-full h-px bg-text" />
                                        <p>{help.content}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="w-full h-px bg-text/20" />
                </div>
            </section>
            <section
                className="bg-bg custom-screen flex items-center justify-between py-16 relative z-10"
                id="uber-mich"
            >
                <div className="w-full pb-16 md:pb-32">
                    <div className="flex flex-wrap items-end justify-between w-full">
                        <motion.h1
                            className="text-[20vmin] leading-none"
                            initial={{
                                opacity: 0,
                                y: 100,
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{
                                duration: 1,
                            }}
                        >
                            Über
                            <br />
                            mich
                        </motion.h1>

                        <img src="/kz.webp" alt="Karolina Zielinska" />
                    </div>
                    <div className="h-px w-full bg-text/20 mb-4" />
                    <div className="flex w-full justify-between gap-24 md:gap-4 flex-wrap">
                        <div>
                            <p className="max-w-[68ch] mb-2">
                                Dank meiner umfangreichen Erfahrung in
                                Deutschland und meiner tiefen Leidenschaft für
                                die deutsche Sprache habe ich meine
                                Dienstleistungen so konzipiert, dass sie Ihnen
                                dabei helfen, sich problemlos in Polen
                                zurechtzufinden.
                            </p>
                            <p className="max-w-[67ch]">
                                Meine Mission besteht darin, eine reibungslose
                                Unterstützung in all Ihren Bedürfnissen in Polen
                                zu gewährleisten, unabhängig davon, ob Sie
                                bereits in Polen leben oder eine Umzug nach
                                Polen planen.
                            </p>
                        </div>
                        <div>
                            <h2 className="text-xl max-w-[32ch] mb-2">
                                Lassen Sie mich Ihr Führer und Unterstützer in
                                Polen sein.{" "}
                            </h2>
                            <p className="max-w-[56ch]">
                                Mein Fachwissen und meine Leidenschaft für die
                                deutsche Sprache ermöglichen es mir,
                                unvergleichliche Hilfe für deutschsprachige
                                Personen anzubieten, die sich im Leben in Polen
                                zurechtfinden.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default App;
